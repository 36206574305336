.block--service {
    background:#fff;
    position:relative;
    margin-bottom:20px;
    > div {
        padding:40px 20px;
        
        .service-title {
            font-size:22px;
            font-weight:700;
            color:#222;
        }
        .service-pricing {
            padding:10px 0px;
        }
        .service-description {
            margin-bottom:40px;
        }
        .service-manage {
            position:absolute;
            width:100%;
            bottom:20px;
            left:0;
            text-align:center;
            display:none;
            i {
                padding:15px;
                background: $accentColor;
                color:#fff;
                border-radius:100%;
            }
        }
    }
    box-shadow:0px 1px 2px rgba(0,0,0,.25);
    &:hover {
        .service-manage {
            display:block;
            
        }
    }
}