.section {
    padding: $sectionPadding;
    position:relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    
    .section-overlay {
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index:0;
    }

    .w-shadow {
        box-shadow: $shadow;
    }

    .grid-container {
        position:relative;

        > .grid-x {
            margin-bottom:20px;
        }
        > .grid-x:last-child {
            margin-bottom:0px;
        }
    }
    .hero-headline, .section-title {
        font-weight: 700;
        font-size: $sectionTitleSize;
        margin-bottom:40px;
        line-height:1.1;

        @media(max-width:1023px){
            margin-bottom:20px;
        }
        @media(max-width:640px){
            font-size: $sectionTitleSizeMobile;
        }
    }

    .hero-subheadline, .section-subtitle {
        font-weight:bold;
        font-size: $sectionSubTitleSize;
        line-height:1.3;
    }

    .hero-subheadline {
        font-weight:normal;
        margin-top:-20px;
        margin-bottom:20px;
    }


    @media(max-width:1023px){
        padding: $sectionPaddingMobile;
    }

}