.reveal {
    padding:30px 60px;
    border-radius:0px;
    @media(max-width:1200px){
        padding:20px 20px;
    }

    .logo {
        max-width:180px;
        text-align:center;
        margin:auto;
        margin-bottom:10px;
    }

    @media(max-width:640px){
        max-width:90%;
        margin-left:auto;
        margin-right:auto;
        top:20px !important;
        min-height:0;
        height:auto;
    }

    .data-close {
        cursor:pointer;
        padding:5px;
        position:absolute;
        top:20px;
        right:20px;
        color:#222;
        font-size:24px;

    }
}