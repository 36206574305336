.form {
    background:#f1f1f1;
    padding:60px;
    textarea {
        min-height:100px;
    }

    .form-submit {
        margin-top:40px;
    }
}

.form-testing-data, .testing-data {
    margin-bottom:5px;
    position:absolute;
    font-size:8px;
    margin-top:-5px;
    display:none;
    span {
        color: firebrick;
        border-bottom:1px dotted;
        display:inline-block;
        cursor:pointer;
    }
}


.form-title {
    font-family: $headingFont;
    font-weight:bold;
    margin-bottom:10px;
    color: $colorBlueDark;
    font-size:30px;
    line-height:1.1;
}

.form-subtitle {
    font-size:16px;
    margin-bottom:5px;
    line-height:1.3;
}

.test-mode {
    .form-testing-data, .testing-data {
        display:block;
    }
    .infusionsoft-form-wrapper {
        .infusion-recaptcha, .form button {
            pointer-events:none;
        }
    }

    .button.test-button {
        cursor:pointer;
    }
}

.infusionsoft-form-wrapper {
    padding:20px;
    font-size:14px;

    @media(max-width:500px){
        padding:0px;
    }

    .infusion-submit {
        text-align:center;
    }

    .infusion-field {
        display:flex;
        margin-bottom:8px;
        label,
        input {
            display:inline-block;
            text-align:left;

        }
        label {
            width:40%;
        }
        input {
            width:60%;
        }
    }

    .infusion-recaptcha, button, .button {
        cursor: pointer;
        background: $accentColor !important;
        min-width:140px;
        line-height:1;
        transition: ease-in-out .4s;
        padding:12px 30px;
        color:#fff;
        font-weight:600;
        border-radius:30px;
        &:hover {
            opacity:.8 !important;
        }
        @media(max-width:500px){
            width:100% !important;
        }
    }

    

    form {
        max-width:600px;
        margin:auto;
        table {
            margin-bottom:0;
        }

        tbody tr:nth-child(even) {
            background:#fff;
        }

        tbody, tfoot, thead {
            border:0px solid;
        }
        tbody td, tbody th {
            padding:0;
            padding-bottom:5px;
        }

        tr {
            td:first-child {
                width:50%;
                padding-bottom:2px;
                line-height:1;
                label {
                    padding-right:5px;
                    line-height:1;
                    font-weight:500;
                }
            }

            td:nth-child(2){
                width:50%;
            }

            @media(max-width:500px){
                margin-bottom:5px;
                display:block;

                td:first-child,
                td:nth-child(2){
                    width:100% !important;
                    display:block;
                    
                }
                input {
                    width:100%;
                }
            }
        }
    
        input, select {
            margin-bottom: 0px;
            padding: 0 10px;
            font-size: 14px;
            height: 25px;
            border-radius: 0px;
            background: none;
        }
    }
}