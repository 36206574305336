.check-list {

    .check-item {
        padding:20px 20px;
        border-bottom:1px solid $borderColor;
        margin-bottom:0px;
        display:flex;
        align-items:center;
        
        &:last-child {
            border-bottom:0px solid;
        }
        .far {
            margin-right:20px;
            color: $colorGreen;
        }

        .item-text {
            font-weight:bold;
            font-size:18px;
        }

        @media(max-width:640px){
            padding:10px;
            .item-text {
                font-size: 14px;
                font-weight:bold;
                line-height:1.3;
            }
        }
    }
}