.block {
    padding:0px;
    background:rgba(255,255,255,.4);

    .block-content {
        border:0px solid $borderColor;
        padding:20px;
        background:#fff;
    }

    &.w-shadow {
        box-shadow: $shadow;
    }
    &.w-radius {
        border-radius:0px;
        .block-content {
            border-radius: 0px;
        }
    }
}