.footer {
    font-size:12px;
    padding:40px 0px;
    color:#777;
    background:#f3f5f7;
    height:10vh;
    
    .button {
        margin-bottom:40px;
    }

    p {
        font-size:12px;
        color:#777;
    }

    .footer-badges {

        .img-wrapper {
            &:last-child {
                margin-bottom:20px;
            }
        }
        img {
            max-height:75px;
        }
    }

}