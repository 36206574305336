.accordion-wrapper {
    .accordion {
        border:0px solid;

        .accordion-item {
            border-top:1px solid $borderColor;

            &:first-child {
                border-top:0px solid;
            }

            .accordion-title {
                border: 0px solid;
                color:#222;
                font-size:16px;
                font-weight:600;
                padding:0px;
                line-height:1.3;
                padding-left:20px;
                padding-right:45px;
                padding-top:15px;
                padding-bottom:15px;
                background: transparent !important;
                display:flex;
                align-items:center;
                i {
                    padding:8px;
                    border-radius:100%;
                    background:#222;
                    color:#fff;
                    margin-right:15px;
                }
            }
    
            .accordion-content {
                border:0px solid;
                padding:0px;
                margin-top:10px;
                padding-left:20px;
                padding-right:20px;
                color: $colorTextGrey;
                p {
                    color: $colorTextGrey;
                }
            }
            &.is-active {
                .accordion-title {
                    padding-bottom:0px;
                }
            }
        }
    }
}