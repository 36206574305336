.off-canvas--editor {

    .theme-item {
        padding:30px;
        border:1px solid #aaa;
        margin-bottom:30px;
        cursor:pointer;

        .activePreview--tag,
        .activeLive--tag {
            display:none;
        }
        &.activePreview {
            opacity: .4;
            cursor:not-allowed;
            .activePreview--tag {
                display:inline-block;
            }
        }
        &.activeLive{
            .activeLive--tag {
                display:inline-block;
            }
        }
    }

    .theme-selection-grid {
        max-height:400px;
        overflow:scroll;
    }
}