.card-items {
    .card-items-wrapper {
        @media(min-width:1024px){
            margin-left:-5px;
            margin-right:-5px;
        }
        
    }

    .card-item {
        padding:40px 15px;
        background:blue;
        margin-right:5px;
        margin-left:5px;
        color:#fff;
        box-shadow: 0px 2px 8px rgba(0,0,0,.2);
        @media(min-width:1024px){
            width: calc(33.33333% - 10px);
        }
        
        @media(max-width:1023px){
            margin-bottom:20px;
        }

        &.blue {
            background:$colorBlue;
        }

        &.blue-dark {
            background: $colorBlueDark;
        }

        &.green {
            background: $colorGreen;
        }

        &.grey {
            background: $colorGreyBlue;
            color:#222;
        }

        &.black {
            background: $colorBlack;
        }
        
        .card-header {
            display:block;
            text-align:center;
            font-size:20px;
            font-weight:bold;
            margin-bottom:0px;
            line-height:1.2;
            padding:0px 20px;
        }

        .card-icon {
            padding:20px;
            text-align:center;

            i {
                font-size:40px;
            }
            
        }

        .card-content {
            text-align:center;
            padding:20px;
            &.button-only {
                margin-top:60px;
            }
        }
    }
}