//spacing && general aesthetic
$containerMaxWidth: 1200px;
$sectionPadding: 100px 0px;
$sectionPaddingMobile: 50px 0px;
$radius: 20px;
$shadow: 0 2px 15px 0 rgba(0, 0, 0,.15);

//colors
$accentColor: #2e19dc; // #2ccd73; // #41c77c; //#29c56e; // #f5801a;
$navBg: #32323d; //#26272c;//#2e3143;
$navTitleColor: #4fc04d;
$navLinkColor: #aeaeae;
$navLinkHoverColor: #fff;
$navLinkBGHoverColor: rgba(255,255,255,.1);
$tableHeaderColor: #aab2bd;
$fontColor: #222;
$borderColor:#e1e1e1;
$bodyBg: #fff;
$colorBlue: #0e96da; // #0784c3;
$colorGreen: #05c08c; // #0eb083; // #21a27e; // #47b472;
$colorBlueDark: #233741;
$colorGreyBlue: #eff3f6;
$colorBlack: #111;
$colorTextGrey: #586971;
$colorPurple: #372592;
$colorRed:  #fd5356;

//typography
$mainFont: "Work Sans", arial;
$headingFont: "Work Sans", arial;
$bodyFontSize: 16px;
$sectionTitleSize: 42px;
$sectionTitleSizeMobile: 26px;
$sectionSubTitleSize: 24px;
$sectionSubTitleSizeMobile: 18px;

// 3 main breakpoints are referenced throughout styles and Foundation framework.
// 1024px - ? = large (desktop)
// 640px - 1023px  = medium (tablet/large phone)
// 0px - 640px  = small (mobile phones)
//
// Changing these breakpoints is not advised unless willing to update Foundation framework manually. Instead, add custom styles as needed. 