.header {
    background: #fff;
    color:#222;
    padding:0px 0px;

    .logo {
        max-width:200px;
    }

    .menu {
        float:right;
        a {
            font-weight:600;
            color:#222 !important;
        }
        i {
            margin-left:5px;
            padding:7px;
            color:#222;
            font-size:28px;
            vertical-align: middle;
        }
    
        .user {
            .greeting {
                font-weight:600;
                .user-menu-toggle {
                    cursor:pointer;
                }
            }
        }
    
        .active-site {
            font-weight:600;
        }
    }
    
    
    
}
.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
    display:none;
}

.sticky {
    z-index:999;
}