@media print, screen and (min-width: 64em) {
    .off-canvas-content.has-reveal-left, .position-left.reveal-for-large~.off-canvas-content {
        margin-left: 350px;
    }
}
.off-canvas {
    background: $navBg;
    width: 350px;
    
    // .logo,.site-selector {
    //     background:#424348;
    // }
    .logo {
        color:#fff;
        width:100%;
        text-align:left;
        padding: 30px;
        font-size: 30px;
        font-weight: 600;
        line-height:1.1;
        padding-bottom:60px;
        font-size:44px;
        small {
            position:relative;
            top:-5px;
            font-size:16px;
        }
    }
    .site-selector {
        font-size:18px;
        font-weight:500;
        .welcome {
            font-weight:600;
            font-size:18px;
            padding-bottom:5px;
        }
        padding:0px 30px;
        padding-bottom:30px;
        color:#fff;
    }
    .menu { 
        margin-bottom:40px;
        li {
            h3 {
                color:$navTitleColor;
                padding:0px 30px;
                font-size:18px;
                text-transform:uppercase;
                letter-spacing:2px;
                font-weight:600;
                margin-bottom:5px;
                //padding-bottom:15px;
                // span {
                //     padding-right:40px;
                //     padding-bottom:10px;
                //     border-bottom:2px solid #fff;
                // }
            }
            a {
                color: $navLinkColor;
                font-weight: 500;
                padding: 15px 30px;
                font-size: 16px;
                transition: ease-in-out .2s;
                &.disabled {
                    pointer-events: none;
                    opacity:.4;
                    cursor:disabled;
                }
                &:hover, &.active {
                    color: $navLinkHoverColor;
                    background: $navLinkBGHoverColor;
                    opacity:1;
                }
                svg, i {
                    float:right;
                    vertical-align: middle;
                    text-align:center;
                    font-size:18px;
                }
            }
        }
    }

    .tile {
        padding: 20px 10px;
        background: #fff;
        margin-top: 20px;
        //box-shadow: 0px 0px 5px rgba(0,0,0,.1);
        &.accordions {
            padding:0;

            .accordion-title,
            .accordion-content {
                padding:20px 30px;
                &:hover {
                    background:#fff;
                    opacity:1;
                }
            }

            .accordion-title {
                font-size:20px;
                color:#222;
                font-weight:600;
            }

            .accordion-content {
                padding:0px 30px 20px;
            }

            .is-active {
                .accordion-title,
                .accordion-content {
                    background:#eaeaea;
                    border:0;
                }
            }
        }
        .form {
            padding:0;
            background:#fff;

            label {
                font-weight:600;
            }

            input,textarea {
                margin-bottom:20px;
            }
        }
    }
}